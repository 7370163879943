<template>

  <b-card>
    <div class="row">
      <div class="col-12">
        <hwa-input
          v-model="content.title"
          label="Title"
        />
      </div>
      <div class="col-12 mt-2">
        <label for="">Content</label>
      </div>
      <div class="col-12">
        <quill-editor
          v-model="content.content"
        />
      </div>
      <div class="col-12 mt-2">
        <hwa-button
          :loading="loading"
          class="float-right"
          label="Update"
          @onClick="updateContent()"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
// eslint-disable-next-line
  import 'quill/dist/quill.core.css'
// eslint-disable-next-line
  import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
  import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import HwaInput from '@/layouts/components/HwaInput.vue'
import { BCard } from 'bootstrap-vue'
import showToast from '@/mixins/showToast'
import HwaButton from '@/layouts/components/HwaButton.vue'

export default {
  name: 'PrivacyContent',
  components: {
    HwaButton,
    HwaInput,
    quillEditor,
    BCard,
  },
  mixins: [showToast],
  data: () => ({
    content: {
      title: '',
      content: '',
    },
    loading: false,
  }),
  computed: {
    privacyContent() {
      return this.$store.getters['auth/siteContent']('privacy')
    },
  },
  watch: {
    privacyContent(val) {
      this.content = val
    },
  },
  created() {
    this.content = this.privacyContent
  },
  methods: {
    updateContent() {
      this.loading = true
      this.$http.put(`api/site_contents/${this.content.id}`, this.content)
        .then(res => {
          this.$store.commit('auth/updateSiteContent', res.data)
          this.showSuccess('Updated Successfully')
          this.loading = false
        }).catch(err => {
          this.loading = false
          this.showError('Failed to update')
        })
    },
  },
}
</script>
